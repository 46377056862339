import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {ClipboardService} from 'ngx-clipboard';


export interface ShareLinkData {
    url: string;
}


@Component({
    selector: 'session-expiring-dialog',
    templateUrl: 'share-link.component.html',
    styleUrls: []
})
export class ShareLinkComponent implements AfterViewInit {

    constructor(
        public dialogRef: MatDialogRef<ShareLinkComponent>,
        private alert: JhiAlertService,
        private clipboard: ClipboardService,
        @Inject(MAT_DIALOG_DATA) public data: ShareLinkData,
    ) {
    }

    ngAfterViewInit(): void {
    }

    close() {
        this.dialogRef.close(false);
    }

    copy() {
        this.clipboard.copy(this.data.url);
        this.alert.success('entity.shareLink.linkCopied');
    }

    copyAndClose() {
        this.copy();
        this.dialogRef.close(true);

    }
}
