import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * @deprecated
 */
@Injectable({providedIn: 'root'})
export class StateStorageService {
    constructor(
        private $sessionStorage: SessionStorageService
    ) {}

    /**
     * @deprecated
     */
    storePreviousState(previousStateName, previousStateParams) {
        const previousState = { 'name': previousStateName, 'params': previousStateParams };
        this.$sessionStorage.store('previousState', previousState);
    }

    /**
     * @deprecated
     */
    getDestinationState() {
        return this.$sessionStorage.retrieve('destinationState');
    }

    /**
     * @deprecated
     */
    storeUrl(url: string) {
        this.$sessionStorage.store('previousUrl', url);
    }

    /**
     * @deprecated
     */
    getUrl() {
        return this.$sessionStorage.retrieve('previousUrl');
    }

    /**
     * @deprecated
     */
    storeObjectUrl(url: string) {
        this.$sessionStorage.store('objectUrl', url);
    }

    /**
     * @deprecated
     */
    getObjectUrl() {
        return this.$sessionStorage.retrieve('objectUrl');
    }
}
