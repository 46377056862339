<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" [color]="findMainColor()" [fullScreen]="true"
             type="ball-atom"></ngx-spinner>

<nav class="navbar navbar-expand-lg navbar-dark bg-white fixed-top top-navbar" id="mainNav"
     *jhiHasAnyAuthority="'AUTHENTICATED'">

    <div class="collapse navbar-collapse" id="navbarResponsive">
        <li style="height:40px;"></li>

        <ul class="navbar-nav navbar-sidenav" id="exampleAccordion" style="margin-top:0px">
            <div style="display:block;width:100%;background-color: #dc241f;"></div>
            <div *ngIf="isNavbarAvailable()">
                <li style="display:block" data-toggle="tooltip" data-placement="bottom">

                    <div style="width:150px; padding-top:8px; text-align: left" class="d-flex flex-row">
                        <div class="nav-link-text left-logo" *ngIf="currentProject">

                            <div style="padding-left:12px;padding-right:0;"
                                 class="mb-3">

                                <img
                                    [src]="currentProject?.getProjectImageUrl() | safe: 'resourceUrl'"
                                    style="max-width: 120px;" *ngIf="currentProject.bytesContentType != null"/>

                                <img src="favicon2.png"
                                     style="max-width: 120px;" *ngIf="currentProject.bytesContentType == null"/>
                            </div>
                            <div class="d-flex">

                                <div class="d-flex text-left">
                                    <button type="button" class="btn btn-link ml-0 " [matMenuTriggerFor]="projectMenu"
                                            style="width:10px;heigth:10px;margin-top:auto" aria-haspopup="true"
                                            aria-expanded="false">
                                        <i class="fa fa-bars"></i>
                                    </button>


                                    <mat-menu #projectMenu="matMenu" yPosition="below" class="project-menu"
                                              style="width: 300px">
                                        <div style="width: 280px">
                                            <div class="dropdown-item"><span class="ml-3 prime"
                                                                             jhiTranslate="global.navbar.availableProjects"></span>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <a *ngIf="canAddProject" class="dropdown-item add-new-project"
                                               routerLink="project-vdr"
                                               href="javascript:void(0);">+&nbsp; <span
                                                jhiTranslate="global.navbar.show.allProjects"></span>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <mat-form-field *ngIf="projects.length >= 5"
                                                            style="width: 100%; padding-left: 10px; padding-right: 10px">
                                                <input #searchInput matInput placeholder="Wyszukaj"
                                                       (click)="$event.stopPropagation()"
                                                       (keyup)="onProjectSearch(searchInput.value)"/>
                                            </mat-form-field>
                                            <div class="dropdown-divider"></div>
                                            <div class="projects-list" style="display:flex; flex-direction: column">

                                                <a href="javascript:void(0);" *ngFor="let project of filteredProjects"
                                                   class="project-dropdown-item" style="height:40px"
                                                   (click)="changeProjectClick(project)"
                                                   [ngClass]="{'activeProject':project.id===currentProject.id}">

                                                    <span class="project-icon">

                                                         <span>
                                                            <img
                                                                [src]="project?.getProjectImageUrl() | safe: 'resourceUrl'"
                                                                style="max-width: 120px;max-height:40px;"
                                                                *ngIf="project?.bytesContentType != null"/>

                                                            <img src="favicon2.png"
                                                                 style="max-width: 120px;max-height:40px;"
                                                                 *ngIf="project?.bytesContentType == null"/>

                                                   </span>
                                                    </span>
                                                    <span
                                                        style="display: block ruby; overflow: hidden; text-overflow: ellipsis"
                                                        class="float-right mr-4">{{ project?.name }}</span>
                                                </a>
                                            </div>
                                        </div>

                                    </mat-menu>


                                </div>
                                <div class="projectName">
                                    {{ findProjectName() }}
                                </div>
                            </div>
                        </div>

                    </div>

                </li>

                <li style="height: 30px"></li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right" *jhiHasAnyAuthority="['ADMIN']">
                    <a class="nav-link" routerLink="">
                        <mat-icon class="icon" svgIcon="start"></mat-icon>
                        <span class="nav-link-text" jhiTranslate2="global.leftMenu.documents">Start</span>
                    </a>
                </li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right" *jhiHasAnyAuthority="'DOCUMENT'">
                    <a class="nav-link" routerLink="/files" routerLinkActive="active">
                        <mat-icon class="icon" svgIcon="nav_documents"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.documents">Dokumenty2</span>
                    </a>
                </li>
                <li class="nav-item" data-toggle="tooltip" data-placement="right"
                    *jhiHasAnyAuthority="'USER_MANAGEMENT'">
                    <a class="nav-link" routerLink="user-management" routerLinkActive="active">
                        <mat-icon class="icon" svgIcon="nav_users"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.users">Użytkownicy2</span>
                    </a>
                </li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right"
                    *jhiHasAnyAuthority="['QA','QA_DELETE','QA_WRITE','QA_SHARE']">
                    <a class="nav-link" routerLink="question" routerLinkActive="active">
                        <!--<i class="fa fa-fw fa-comment"></i>-->
                        <mat-icon class="icon" svgIcon="nav_chat"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.qa">Q&amp;A</span>
                    </a>
                </li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="reports">
                    <a class="nav-link" routerLink="reports" routerLinkActive="active">
                        <mat-icon class="icon" svgIcon="nav_reports"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.reports">Reports</span>
                    </a>
                </li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right"
                    *jhiHasAnyAuthority="['PROJECT_PROPERTIES']">
                    <a class="nav-link" routerLink="project-management" routerLinkActive="active">
                        <mat-icon class="icon" svgIcon="nav_settings"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.project">Project</span>
                    </a>

                </li>

                <li class="nav-item" data-toggle="tooltip" data-placement="right"
                    *jhiHasAnyAuthority="['SUPER_ADMIN']">
                    <a class="nav-link" routerLink="monitoring" routerLinkActive="active">
                        <mat-icon class="icon">trending_up</mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.management">Zarządzanie</span>
                    </a>

                </li>


                <li class="nav-item" data-toggle="tooltip" data-placement="right">
                    <a class="nav-link" routerLink="help" routerLinkActive="active">
                        <mat-icon class="icon" svgIcon="nav_help"></mat-icon>
                        <span class="nav-link-text" jhiTranslate="global.leftMenu.help">Help123</span>

                    </a>
                </li>
            </div>

            <li class="nav-item" data-toggle="tooltip" data-placement="right">

                <button type="button" class="btn btn-link" [matMenuTriggerFor]="menu"
                        style="width:100%"
                        aria-expanded="false">
                    <i *ngIf="!getUserIdentity()?.bytes" class="fa fa-fw fa-user-circle" style="font-size:40px;"></i>
                    <div *ngIf="getUserIdentity()?.bytes" class="image-cropper">
                        <img class="profile-pic avatar"
                             [src]="'data:image/jpeg;base64,' + getUserIdentity()?.bytes"
                             alt="file image"/>
                    </div>
                </button>
                <mat-menu #menu="matMenu" class="userMenuDialog" yPosition="above">

                    <div id="user-info">

                        <div class="username text-center">{{ getUserFullname() }}</div>
                        <div class="dropdown-divider"></div>
                        <!--                    <a class="dropdown-item" (click)="toggleNotifications" routerLinkActive="true">{{'global.leftMenu.activateNotifications'-->
                        <!--                        | translate}}</a>-->
                        <a class="dropdown-item" routerLink="settings"
                           routerLinkActive="true">{{
                                'global.leftMenu.profileSettings'
                                    | translate
                            }}</a>
                    </div>
                </mat-menu>
                <a class="nav-link text-center" (click)="logout()">
                    <i class="fa fa-fw fa-power-off"></i> <span class="logout-text nav-link-text"
                                                                jhiTranslate="global.leftMenu.logout">Logout</span>
                </a>
                <div class="flags">
                    <a href="javascript:void(0);" (click)="changeLanguage('pl');"><span class="flag flag-pl"></span></a>
                    <a href="javascript:void(0);" (click)="changeLanguage('en');"><span class="flag flag-gb"></span></a>
                    <a href="javascript:void(0);" (click)="changeLanguage('de');"><span class="flag flag-de"></span></a>
                    <a href="javascript:void(0);" (click)="changeLanguage('fr');"><span class="flag flag-fr"></span></a>
                    <a href="javascript:void(0);" (click)="changeLanguage('cs');"><span class="flag flag-cz"></span></a>
                    <a href="javascript:void(0);" (click)="changeLanguage('es');"><span class="flag flag-es"></span></a>
                </div>

                <div>
                    <git-version></git-version>
                </div>

            </li>

        </ul>
        <div>
        </div>
    </div>
</nav>
<div class="logo secudo-logo">
    <div class="mb-auto  mt-auto">
        <img src="../../content/images/secudo_watermark2.png"/>
    </div>

    <div class="mt-1 timer" *ngIf="isAuthenticated()">
                <span> {{ sessionTimer.minutes }}:{{ sessionTimer.seconds }}
                    <a (click)="showTimerDialog()" class="fa fa-clock-o"></a>
                </span>
    </div>
</div>
