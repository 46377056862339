import {Component} from '@angular/core';
import {DirDTO} from './directory-tree.component';
import {SelectedItem} from './document-list.component';
import {DirectoryDocumentService} from './directory-document.service';
import {HttpResponse} from '@angular/common/http';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {PdfDocumentViewerComponent} from '../../account';
import {MatDialog} from '@angular/material/dialog';
import {TreeElement, TreeElementType} from '@entities/directory-document/dto/tree-element';
import {ProjectVdrService} from '@admin/project-vdr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'jhi-directory-document',
    templateUrl: 'directory-document-note.component.html',
    styleUrls: ['directory-document.scss'],
})
export class DirectoryDocumentNoteComponent {
    selectedDirectory: DirDTO;
    selectedDocuments: SelectedItem[];
    directoryGenerated: number;
    ddvSettings: DirectoryDocumentViewSettingsDto;
    listDirectorySelection: number;
    refreshDocuments: number;
    currentUrlSegments: UrlSegment[];
    projectRootDirectory: string;

    constructor(
        private projectVdrService: ProjectVdrService,
        private directoryDocumentService: DirectoryDocumentService,
        private dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router,
        private spinner: NgxSpinnerService
    ) {
        this.route.url.subscribe((segments) => this.onUrlChange(segments));

        this.directoryDocumentService.getSettings()
            .subscribe((res: HttpResponse<DirectoryDocumentViewSettingsDto>) => {
                this.ddvSettings = res.body;
            });
    }

    onSelectedDictionary(dto: DirDTO) {
        this.selectedDirectory = dto;
        this.selectedDocuments = [];
    }

    onSelectedDocuments(documents: SelectedItem[]) {
        this.selectedDocuments = documents;
    }

    onDirectoryCreaction(random: number) {
        this.directoryGenerated = random;
    }

    onListDirectorySelection(selectedDirectoryId: number) {
        this.listDirectorySelection = selectedDirectoryId;
    }

    onRefreshDocuments(refresh: number) {
        this.refreshDocuments = refresh;
    }

    private onUrlChange(segments: UrlSegment[]) {
        this.spinner.show();
        this.currentUrlSegments = segments;
        const fullPath = '/' + segments.join('/');

        // if no path is provided - redirect to root directory
        if (!segments.length) {
            if (!this.projectRootDirectory) {
                const subdomain = document.location.host.split('.')[0];
                this.projectVdrService.findByUrl(subdomain).subscribe((project) => {
                    this.projectRootDirectory = project.body.rootDirectory;
                    this.router.navigateByUrl(`/files/${this.projectRootDirectory}/`).then((_) => this.spinner.hide());
                });
            }

            this.router.navigateByUrl(`/files/${this.projectRootDirectory}/`).then((_) => this.spinner.hide());
            return;
        }

        this.directoryDocumentService.findByPath(fullPath)
            .then((element) => {
                if (element.type === TreeElementType.directory) {
                    const nodeType = this.getNodeTypeForDirectory(element);

                    this.selectedDirectory = new DirDTO(element.id, element.name, element.businessIndex, nodeType);
                    return;
                }

                this.openFile(element);
            }).then(() => this.spinner.hide());
    }

    private getNodeTypeForDirectory(element: TreeElement) {
        switch (element.name) {
            case 'tree.specialNode.damaged':
                return 'DAMAGED';
            case  'tree.specialNode.trash':
                return 'TRASH';
            default:
                return 'DIRECTORY';
        }
    }

    private openFile(element: TreeElement) {
        this.dialog.open(PdfDocumentViewerComponent, {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            maxWidth: '100vw',
            panelClass: 'pdf-viewer-dialog',
            autoFocus: false,
            data: {documentId: element.id}
        }).beforeClosed().subscribe(() => {
            // on close load previous directory
            this.router.navigateByUrl('files/' + this.currentUrlSegments.slice(0, -1).join('/'));
        });
    }

    viewFileProcessingState(event: any) {
        this.selectedDirectory = new DirDTO(-2, null, null, 'DAMAGED');
    }
}
