
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

import { ProjectVdr } from './project-vdr.model';
import { createRequestOption } from '../../shared';
import {withCache} from '@ngneat/cashew';

export type EntityResponseType = HttpResponse<ProjectVdr>;

@Injectable()
export class ProjectVdrService {

    private resourceUrl =  SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) { }

    create(project: ProjectVdr): Observable<EntityResponseType> {
        const copy = this.convert(project);
        return this.http.post<ProjectVdr>(this.resourceUrl, copy, { observe: 'response' }).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(project: ProjectVdr): Observable<EntityResponseType> {
        const copy = this.convert(project);
        return this.http.put<ProjectVdr>(this.resourceUrl, copy, { observe: 'response' }).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    updateProjectByClient(project: ProjectVdr): Observable<HttpResponse<ProjectVdr>> {
        return this.http.post<ProjectVdr>(this.resourceUrl + '/client', this.convert(project), { observe: 'response' })
            .pipe();
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<ProjectVdr>(`${this.resourceUrl}/${id}`, { observe: 'response'}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByUrl(url: string): Observable<EntityResponseType> {
        return this.http.get<ProjectVdr>(`${this.resourceUrl}/byUrl/${url}`, { observe: 'response', context: withCache()}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    getIconInfo(id: number): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.resourceUrl}/${id}/icon/info`, { observe: 'response'}).pipe(
            map((res: any) => this.convertResponse(res)));
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: ProjectVdr = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<ProjectVdr[]>): HttpResponse<ProjectVdr[]> {
        const jsonResponse: ProjectVdr[] = res.body;
        const body: ProjectVdr[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to ProjectVdr.
     */
    private convertItemFromServer(project: ProjectVdr): ProjectVdr {
        const copy: ProjectVdr = Object.assign({}, project);
        return copy;
    }

    /**
     * Convert a ProjectVdr to a JSON which can be sent to the server.
     */
    private convert(project: ProjectVdr): ProjectVdr {
        const copy: ProjectVdr = Object.assign({}, project);
        return copy;
    }
}
