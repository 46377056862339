import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

import {DirectoryVdr} from './directory-vdr.model';
import {DirectoryTreeDto} from '../directory-document/dto/directory-tree-dto.model';
import {DirectoryInfoDto} from '../directory-document/dto/directory-info-dto.model';
import {DirectoryStructure} from '../file-upload/directory-structure.component';
import {HttpCacheManager, withCache} from '@ngneat/cashew';

export type EntityResponseType = HttpResponse<DirectoryVdr>;

@Injectable()
export class DirectoryVdrService {
    private static cachePrefix = 'directory-';

    private resourceUrl = SERVER_API_URL + 'api/directories';
    private moveDirectoryUrl = SERVER_API_URL + 'api/moveDirectory';
    private copyDirectoryUrl = SERVER_API_URL + 'api/copyDirectory';
    private createUrl = SERVER_API_URL + 'api/create-directory';
    private createStructure = SERVER_API_URL + 'api/create-structure';
    private changeDirectoryNameUrl = SERVER_API_URL + 'api/changeDirectoryName';
    private setLastVisitedDirectory = SERVER_API_URL + 'api/directory-last-visited/{id}';
    private resourceReindexUrl = SERVER_API_URL + 'api/directory/reindex';

    constructor(private http: HttpClient, private cacheManager: HttpCacheManager) {
    }

    find(id: number): Observable<EntityResponseType> {
        const cacheKey = DirectoryVdrService.cachePrefix + id;
        const cachedDirectory = this.cacheManager.get(cacheKey);

        if (cachedDirectory) {
            return of(cachedDirectory);
        }

        return this.http.get<DirectoryVdr>(`${this.resourceUrl}/${id}`, {observe: 'response'}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)),
            map((d) => {
                this.cacheManager.set(cacheKey, d);
                return d;
            })
        );
    }

    directoryTree(): Observable<DirectoryTreeDto[]> {
        return this.http.get<DirectoryTreeDto[]>(this.resourceUrl + '/tree', {
            context: withCache()
        });
    }

    directoryDetails(id: number): Observable<DirectoryInfoDto> {
        return this.http.get(this.resourceUrl + '/details/' + id);
    }

    directoriesByParent(id: number): Observable<DirectoryVdr[]> {
        return this.http.get<DirectoryVdr[]>(this.resourceUrl + /by-parent/ + id, {context: withCache()}).pipe(map((directories) => {
            directories.forEach((d) => {
                const cacheKey = DirectoryVdrService.cachePrefix + d.id;
                this.cacheManager.set(cacheKey, d);
            });
            return directories;
        }));
    }

    setLastVisited(id: number): Promise<Object> {
        return this.http.post(this.setLastVisitedDirectory.replace('{id}', String(id)), null).toPromise();
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: DirectoryVdr = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<DirectoryVdr[]>): HttpResponse<DirectoryVdr[]> {
        const jsonResponse: DirectoryVdr[] = res.body;
        const body: DirectoryVdr[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to DirectoryVdr.
     */
    private convertItemFromServer(directory: DirectoryVdr): DirectoryVdr {
        const copy: DirectoryVdr = Object.assign({}, directory);
        return copy;
    }

    /**
     * Convert a DirectoryVdr to a JSON which can be sent to the server.
     */
    private convert(directory: DirectoryVdr): DirectoryVdr {
        const copy: DirectoryVdr = Object.assign({}, directory);
        return copy;
    }

    moveDirectory(directoryId: string, moveToDirectoryId: string): Observable<Object> {
        const apiURL = this.moveDirectoryUrl + '?directoryId=' + directoryId
            + '&moveToDirectoryId=' + moveToDirectoryId;
        return this.http.post(apiURL, {});
    }

    copyDirectory(directoryId: string, copyToDirectoryId: string): Observable<Object> {
        const apiURL = this.copyDirectoryUrl + '?directoryId=' + directoryId
            + '&copyToDirectoryId=' + copyToDirectoryId;
        return this.http.post(apiURL, {});
    }

    createDirectory(directoryName: string, directoryId?: number): Observable<HttpResponse<void>> {
        const params = (!directoryId) ? new HttpParams().set('directoryName', directoryName) :
            new HttpParams().set('directoryName', directoryName).set('directoryId', directoryId.toString());
        return this.http.post<HttpResponse<void>>(this.createUrl, params);
    }

    structure(structure: DirectoryStructure): Observable<DirectoryStructure> {
        return this.http.post<DirectoryStructure>(this.createStructure, structure);
    }

    changeName(directoryId: number, newName: string): Observable<Object> {
        const apiURL = this.changeDirectoryNameUrl + '?directoryId=' + directoryId
            + '&newDirectoryName=' + newName;
        return this.http.post(apiURL, {});
    }

    reindexNumber(directoryId: number): Observable<Object> {
        return this.http.post(this.resourceReindexUrl, directoryId);
    }
}
