import {BaseEntity} from '../../shared';
import {Client} from '@entities/client/client.model';

export class ProjectVdr implements BaseEntity {


    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public clientId?: number,
        public email?: string,
        public dealNo?: string,
        public surname?: string,
        public company?: string,
        public more?: string,
        public phone?: string,
        public type?: string,
        public www?: string,
        public automaticIndexing?: boolean,
        public therm = 'NEVER',
        public thermContent?: string,
        public watermarkFontSize?: number,
        public watermarkOpacity?: number,
        public watermarkColor?: number,
        public watermarkFont?: string,
        public watermarkTextLine?: string,
        public showCompanyName?: boolean,
        public mainColor?: string,
        public twoPhaseAuthentication?: boolean,
        public versioning?: boolean,
        public startDate?: Date,
        public endDate?: Date,
        public questionLimit?: boolean,
        public bytesContentType?: string,
        public client?: Client,
        public rootDirectory?: string,
    ) {
    }

    public getProjectImageUrl(): string {
        return `/api/projects/${this.id}/icon`;
    }
}
