import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SERVER_API_URL} from '../../app.constants';
import {ProjectVdr} from '@admin/project-vdr';
import {Account} from '../user/account.model';
import {CacheBucket, withCache} from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class AccountService {
    private cacheBucket = new CacheBucket();
    constructor(private http: HttpClient) {
    }

    get(): Observable<HttpResponse<Account>> {
        return this.http.get<Account>(SERVER_API_URL + 'api/account', {observe: 'response', context: withCache({bucket: this.cacheBucket})});
    }

    save(account: any): Observable<HttpResponse<any>> {
        this.cacheBucket.clear();
        return this.http.post(SERVER_API_URL + 'api/account', account, {observe: 'response'});
    }

    changeProject(project: ProjectVdr, verificationCode: string): Observable<ProjectVdr> {
        let id = -1;
        if (project) {
            id = project.id;
        }

        this.cacheBucket.clear();
        return this.http.post<ProjectVdr>(SERVER_API_URL + 'api/account/currentProjects/' + id, {verificationCode}, {observe: 'response'})
            .pipe(map((r) => this.plainToClass(r.body)));
    }

    getUserProjects() {
        return this.http.get<ProjectVdr[]>(SERVER_API_URL + 'api/account/currentProjects/')
            .pipe(map((response) => response.map((p) => this.plainToClass(p))));
    }

    saveLanguageToAccount(langKey: string) {
        return this.http.get<ProjectVdr[]>(SERVER_API_URL + 'api/account/changeLanguage/' + langKey);
    }

    acceptTherms(): Observable<HttpResponse<Account>> {
        return this.http.get<Account>(SERVER_API_URL + 'api/account/accept-therms', {observe: 'response'});
    }

    checkThermsNeeded(): Observable<boolean> {
        return this.http.get<boolean>(SERVER_API_URL + 'api/account/is-therms-needed');
    }

    sendVerificationCode(projectId: number): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/sendVerificationCode/' + projectId, {force: true}, {observe: 'response'});
    }

    private plainToClass(project: ProjectVdr): ProjectVdr {
        return Object.assign(new ProjectVdr(), project as ProjectVdr);
    }

}
