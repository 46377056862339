<div fxLayout="row">
    <span mat-dialog-title fxFlex="90%" jhiTranslate="global.sessionTimeout.header"></span>
</div>
<div mat-dialog-content>
    <div class="text-center mb-3 mt-3">
        <div>
            <span jhiTranslate="global.sessionTimeout.info1"></span>:
        </div>
        <div class="mt-2">{{ data.navbar.sessionTimer.minutes }}:{{ data.navbar.sessionTimer.seconds }}</div>
        <div class="mt-2">
            <span jhiTranslate="global.sessionTimeout.info2"></span>:

        </div>
    </div>

    <div class="mt-3 text-right" style="margin-top:auto !important">
        <mat-divider></mat-divider>
        <div class="mt-3">
            <button class="btn mr-2" (click)="close()"><span class="fa fa-remove"></span><span
                class="ml-2"><span jhiTranslate="entity.action.cancel"></span></span>
            </button>
            <button class="btn-primary btn" (click)="refreshSession()"><span class="fa fa-refresh"></span><span
                class="ml-2" jhiTranslate="metrics.refresh.button">Odśwież</span></button>
        </div>
    </div>
</div>
