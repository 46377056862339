export class DirectoryTreeDto {
    constructor(
        public id?: number,
        public name?: string,
        public children?: DirectoryTreeDto[],
        public parentId?: number,
        public index?: number,
        public indexCumulate?: string,
        public nodeType?: string,
        public fullPath?: string
    ) {
    }
}

export class FlatDirectoryTree {
    constructor(
        public expandable: boolean,
        public level: number,
        public id?: number,
        public name?: string,
        public parentId?: number,
        public index?: number,
        public indexCumulate?: string,
        public nodeType?: string,
        public fullPath?: string,
    ) {
    }
}

export class DirectoryDocumentTreeDto {
    constructor(
        public id?: number,
        public name?: string,
        public children?: DirectoryTreeDto[],
        public documents?: DocumentSimpleDto[],
        public parentId?: number,
    ) {
    }
}

export class FlatDirectoryDocumentTree {
    constructor(
        public expandable: boolean,
        public level: number,
        public id?: number,
        public name?: string,
        public parentId?: number,
        public documents?: DocumentSimpleDto[],
    ) {
    }
}

export class DocumentSimpleDto {
    constructor(
        public id?: number,
        public name?: string,
    ) {
    }
}
