<div class="directory-tree" fxLayout="column">
    <!--<span class="directory-tree-title" jhiTranslate="tree.title">Folders and files</span>-->
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                       [ngStyle]="{'margin-left': getMarginFlat(node.level), 'margin-right': '5px'}"
                       [ngClass]="{ 'background-highlight': activeNode === node }">
            <button *ngIf="node.nodeType==='DIRECTORY'" mat-button (click)="navigateTo(node)"
                    matTooltip="{{node.name | treeIndex: node.indexCumulate:ddvSettings?.indexVisible: true}}" mwlDroppable (drop)="dropped($event, node)">
                <span *ngIf="node.nodeType==='DIRECTORY'">
                    <i class="fa fa-folder " style="font-size: 18px" ></i>
                    <span class="directory-tree-text">{{node.name | treeIndex: node.indexCumulate:ddvSettings?.indexVisible}}</span>
                </span>
            </button>
            <button *ngIf="node.nodeType==='QA'" mat-button (click)="navigateTo(node)">
                <span *ngIf="node.nodeType==='QA'">
                    <mat-icon svgIcon="vdr_chat"></mat-icon>
                    <!-- <i class="fa fa-cogs " style="font-size: 18px" ></i> -->
                    <span class="directory-tree-text">{{node.name}}</span>
                </span>
            </button>
            <button *ngIf="node.nodeType!='DIRECTORY'" mat-button (click)="navigateTo(node)" >
                <span *ngIf="node.nodeType==='TRASH'">
                    <i class="fa fa-trash " style="font-size: 18px" ></i>
                    <span class="directory-tree-text" jhiTranslate="{{node.name}}">{{node.name}}</span>
                </span>
                <span *ngIf="node.nodeType==='DAMAGED'">
                    <i class="fa fa-cogs " style="font-size: 18px" ></i>
                    <span class="directory-tree-text" jhiTranslate="{{node.name}}">{{node.name}}</span>
                </span>
            </button>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
                       [ngStyle]="{'margin-left': getMargin(node.level), 'margin-right': '5px'}"
                       [ngClass]="{ 'background-highlight': activeNode === node }">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name" >
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <button mat-button (click)="navigateTo(node)" style="padding-left: 0 !important;" class="folder-button"
                    matTooltip="{{node.name | treeIndex: node.indexCumulate:ddvSettings?.indexVisible: true}}" mwlDroppable (drop)="dropped($event, node)">
                <i [class]="treeControl.isExpanded(node) ? 'fa fa-folder-open' :  'fa fa-folder' "
                   style="font-size: 18px" ></i>
                <span class="directory-tree-text">{{node.name | treeIndex: node.indexCumulate:ddvSettings?.indexVisible}}</span>
            </button>
        </mat-tree-node>
    </mat-tree>
</div>

