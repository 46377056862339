import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiEventManager, JhiLanguageService} from '@upside-cloud/ng-jhipster';

import {LoginService} from './login.service';
import {StateStorageService} from '../auth/state-storage.service';
import {JhiLanguageHelper} from './../language/language.helper';
import {PasswordResetService} from '../../account/security/password-reset/password-reset.service';
import {DateAdapter} from '@angular/material/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DomainUrl, ProfileService} from '../../layouts';

@Component({
    selector: 'jhi-login-modal',
    templateUrl: './login.component.html',
    styleUrls: ['login.scss']

})
export class JhiLoginModalComponent implements OnInit {

    authenticationError: boolean;
    password: string;
    rememberMe: boolean;
    username: string;
    verificationCode: string;
    credentials: any;
    languages: any[] = [];
    language: any;
    currentLanguage: any;
    message: string;
    passwordShow = false;
    loginInProgress = false;
    rateLimited: boolean;

    private domainUrl: DomainUrl;
    private ACCOUNT_BLOCKED = 'locked';
    private CREDENTIALS_EXPIRED = 'credentialsExpired';
    private RATE_LIMITED = 'Rate Limited';

    constructor(private eventManager: JhiEventManager,
                private profile: ProfileService,
                private loginService: LoginService,
                private stateStorageService: StateStorageService,
                private elementRef: ElementRef,
                private renderer: Renderer2,
                private router: Router,
                private route: ActivatedRoute,
                public activeModal: NgbActiveModal,
                private languageHelper: JhiLanguageHelper,
                private languageService: JhiLanguageService,
                private spinner: NgxSpinnerService,
                private dateAdapter?: DateAdapter<Date>
    ) {
        this.credentials = {};
        profile.getProfileInfo().then((p) => {
           this.domainUrl = new DomainUrl(p.baseUrl);
        });
    }

    ngOnInit() {
        let lang = localStorage.getItem('lang');

        if (!lang) {
            lang = 'pl';
            localStorage.setItem('lang', lang);

        }
        this.currentLanguage = lang;
        this.dateAdapter.setLocale(lang);
        this.languageService.changeLanguage(lang);

        this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#username')).focus();

        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });
    }

    changeLanguage($event) {
        this.currentLanguage = $event.value;
        this.dateAdapter.setLocale(this.currentLanguage);
        this.languageService.changeLanguage(this.currentLanguage);
        localStorage.setItem('lang', this.currentLanguage);
    }


    cancel() {
        this.credentials = {
            username: null,
            password: null,
            rememberMe: true,
            verificationCode: null,
        };
        this.authenticationError = false;
        this.activeModal.dismiss('cancel');
    }

    login() {
        if (this.loginInProgress) {
            return;
        }

        this.resetMessages();
        this.loginInProgress = true;
        this.spinner.show();

        const credentials = {
            username: this.username,
            password: this.password,
            rememberMe: this.rememberMe,
            langKey: this.currentLanguage,
            verificationCode: this.verificationCode
        };
        this.loginService.login(credentials)
            .then(this.handleLogin())
            .catch(this.handleLoginError());
    }

    private handleLoginError() {
        return (error) => {
            this.loginInProgress = false;
            this.spinner.hide();

            if (error.error.message === this.CREDENTIALS_EXPIRED) {
                this.requestResetPassword();
                return;
            }

            if (error.error.message === this.RATE_LIMITED) {
                this.rateLimited = true;
                return;
            }

            this.authenticationError = true;
        };
    }

    private handleLogin() {
        return () => {
            this.loginInProgress = false;
            this.spinner.hide();

            this.activeModal.dismiss('login success');
            if (this.router.url === '/register' || (/^\/activate\//.test(this.router.url)) ||
                (/^\/reset\//.test(this.router.url))) {
                this.router.navigate(['']);
            }

            this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });

            // // previousState was set in the authExpiredInterceptor before being redirected to login modal.
            // // since login is succesful, go to stored previousState and clear previousState
            const redirect = this.route.snapshot.queryParams['return-to'];
            if (redirect && redirect !== '/') {
                this.stateStorageService.storeUrl(null);
                this.router.navigateByUrl(redirect);
                return;
            }
            /*if (this.isMainDomain()) {
                console.log('nie ma subdomeny przekierowujemy na stronę wyboru projektu');
                window.location.replace(this.findProjectManagementUrl());
            } else {
                this.router.navigate(['/project-vdr']);
            }*/

            const navigateTo = this.domainUrl.isSubdomain() ? ['/'] : ['/project-vdr'];
            this.router.navigate(navigateTo);
        };
    }

    register() {
        this.activeModal.dismiss('to state register');
        this.router.navigate(['/register']);
    }

    requestResetPassword(isForcedChange = false) {
        this.activeModal.dismiss('to state requestReset');
        const queryParams = {};
        if (isForcedChange) {
            queryParams['password-expired'] = '👍';
        }

        this.router.navigate(['/reset', 'request'], {
            'queryParams': queryParams
        });
    }

    resetMessages() {
        this.loginInProgress = false;
        this.authenticationError = false;
        this.rateLimited = false;
        this.message = null;
    }

}
