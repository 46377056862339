import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {JhiAlertService, JhiEventManager, JhiParseLinks}from '@upside-cloud/ng-jhipster';

import {ProjectVdr} from './project-vdr.model';
import {ProjectVdrService} from './project-vdr.service';
import {AccountService, ITEMS_PER_PAGE, Principal} from '../../shared';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material/dialog';
import {ReportUploadedDialogComponent} from '../../report/report-uploaded/dialog/report-uploaded-dialog.component';
import {ClientChoiceDialog} from '@entities/client/client-choice-dialog';
import {ClientsDialog} from '@entities/client/clients-dialog';
import {ProjectVdrDeleteDialogComponent} from '@admin/project-vdr/project-vdr-delete-dialog.component';
import {DomainUrl, ProfileService} from '../../layouts';

@Component({
    selector: 'jhi-project-vdr',
    templateUrl: './project-vdr.component.html',
    styleUrls: ['./project.scss'],
})
export class ProjectVdrComponent implements OnInit, OnDestroy {

    searchText = '';
    currentAccount: any;
    projects: ProjectVdr[] = [];
    filterProjects: ProjectVdr[] = [];
    error: any;
    success: any;
    eventSubscriber: Subscription;
    currentSearch: string;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    err: string;
    private domainUrl: DomainUrl;

    constructor(
        private profileService: ProfileService,
        private parseLinks: JhiParseLinks,
        private jhiAlertService: JhiAlertService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: JhiEventManager,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dialog: MatDialog,
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            if (data.pagingParams) {
                this.page = data.pagingParams.page;
                this.previousPage = data.pagingParams.page;
                this.reverse = data.pagingParams.ascending;
                this.predicate = data.pagingParams.predicate;
            }

        });

        this.profileService.getProfileInfo().then((profile) => {
            this.domainUrl = new DomainUrl(profile.baseUrl);

        });

        this.currentSearch = this.activatedRoute.snapshot && this.activatedRoute.snapshot.params['search'] ?
            this.activatedRoute.snapshot.params['search'] : '';

    }

    loadAll() {
        this.getUserProjects();
    }

    getProjectUrl(project: ProjectVdr) {
        return this.domainUrl.findSubdomainUrl(project.www);
    }

    /**
     * @param project
     */
    changeProject(project: ProjectVdr) {
        this.spinner.show();
        document.location.href = this.getProjectUrl(project);
    }

    getUserProjects() {
        this.spinner.show();
        this.accountService.getUserProjects()
            .subscribe((res: ProjectVdr[]) => {
                this.projects = res;
                this.filterProjects = this.projects;
                this.spinner.hide();
                }
            );
    }


    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        const moveSmaller = this.reverse ? 1 : -1;
        const moveLarger = this.reverse ? -1 : 1;
        if (this.projects) {
            this.projects.sort((a, b) => {
                if (a[this.predicate] <= b[this.predicate]) {
                    return moveSmaller;
                } else {
                    return moveLarger;
                }
            });
        }
    }


    clear() {
        this.page = 0;
        this.currentSearch = '';
        this.router.navigate(['/project-vdr', {
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }

    search(query) {
        if (!query) {
            return this.clear();
        }
        this.page = 0;
        this.currentSearch = query;
        this.router.navigate(['/project-vdr', {
            search: this.currentSearch,
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.err = params['error.ipRestricted'];
            if (this.err === '1') {
                this.jhiAlertService.error('error.ipRestricted', null, null);
            }
        });
        this.loadAll();
        this.principal.identity(true).then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInProjects();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: ProjectVdr) {
        return item.id;
    }

    registerChangeInProjects() {
        this.eventSubscriber = this.eventManager.subscribe('projectListModification', (response) => this.loadAll());
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = headers.get('X-Total-Count');
        this.queryCount = this.totalItems;
        // this.page = pagingParams.page;
        this.projects = data;
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }

    searchProject() {
        if(this.searchText) {
            this.filterProjects = this.projects.filter((project) => {
               const projectSearchText = `${project.name} ${project.email} ${project.dealNo}`;
               return projectSearchText.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
            });
        } else{
            this.filterProjects = this.projects;
        }
    }

    clearSearchText(){
        this.searchText = '';
        this.searchProject();
    }

    openUploadedReport(): void {
        this.dialog.open(ReportUploadedDialogComponent, {
            width: '80%',
            height: '90%',
            disableClose: true,
            hasBackdrop: true
        });
    }

    openClientChoice(projectVdr: ProjectVdr): void {
        this.dialog.open(ClientChoiceDialog, {
            width: '50%',
            data: projectVdr,
            disableClose: true,
            hasBackdrop: true
        });
    }

    openClientsDialog(): void {
        this.dialog.open(ClientsDialog, {
            width: '50%',
            height: '90%',
            disableClose: true,
            hasBackdrop: true
        });
    }

    openDeleteProjectDialog(projectVdr: ProjectVdr): void {
        this.dialog.open(ProjectVdrDeleteDialogComponent, {
            width: '50%',
            data: projectVdr,
            disableClose: true,
            hasBackdrop: true
        });
    }
}
