<div fxLayout="row">
    <span mat-dialog-title fxFlex="90%" jhiTranslate="entity.shareLink.header"></span>
</div>
<div mat-dialog-content>
    <div class="text-center mb-3 mt-3">
        <div class="input-group">
            <input id="password" type="text" class="form-control" [(ngModel)]="data.url" >
            <div class="input-group-append">
                <button
                    matTooltip="{{ 'entity.action.copy' | translate }}"
                    class="btn btn-outline-secondary" type="button" (click)="copy()">
                    <i class="fa fa-clipboard"></i>
                </button>
            </div>
        </div>
        <br />
        <small jhiTranslate="entity.shareLink.disclaimer">Użytkownik musi posiadać konto oraz mieć dostęp do tego zasobu</small>

    </div>

    <div class="mt-3 text-right" style="margin-top:auto !important">
        <mat-divider></mat-divider>
        <div class="mt-3">
            <button class="btn mr-2" (click)="close()"><span class="fa fa-remove"></span><span
                class="ml-2"><span jhiTranslate="entity.action.cancel"></span></span>
            </button>
            <button class="btn-primary btn" (click)="copyAndClose()"><span class="fa fa-copy"></span><span
                class="ml-2" jhiTranslate="entity.action.copyAndClose">Skopiuj</span></button>
        </div>
    </div>
</div>
