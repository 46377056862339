export enum TreeElementType {
    directory = 'directory',
    file = 'file'
};

export type TreeElement = {
    id: number
    name: string
    type: TreeElementType
    businessIndex: string
    path: string
};
